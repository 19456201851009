<template>
  <div>
    <v-row class="mt-4">
      <v-col>
        <v-card class="transparent" elevation="12">
          <div class="primary text-h6 text-md-h4 px-4 py-2 primary white--text text-uppercase">
            C&C Industrial is a proud supporter of Nash Autism
          </div>
          <v-card-text class="grey--text text--lighten-2 text-subtitle-1">
            <p>
            Nash Autism is a charitable organization established to help families in need with children whom have special needs. A percentage of all our Concrete Pumping revenue is donated to this charity.
            </p>
            <p>C&C Industrial also assists in a large fund raiser for this program every year known as the Brinkleyville Trail Ride. At this event, horse owners and riders from states away come for fellowship and awareness of Autism to help raise money for those families in need by donations, raffles, and auctions.
            </p>
            <p>Other contributions to this charity might include providing materials and labor, just to build a ramp to their home for accessibility.
            </p>
            <ImageRow
              :images="images"
              title="Nash Austism - C&C Industrial"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageRow from '@/core/components/ImageRow'
export default {
  components: {
    ImageRow
  },
  data () {
    return {
      images: [
        {
          uuid: '3995a504-10a1-4cd6-82f6-758d8fca25da',
          alt: 'Nash Autism - Ride for Reason'
        },
        {
          uuid: '347f8f4f-a37c-41fd-bd89-6a664aa947b2',
          alt: 'Nash Autism - Brinkleyville Trail Ride'
        },
        {
          uuid: '82a00e3e-0efc-4fd3-8039-77b8506f5e69',
          alt: 'Nash Autism - Brinkleyville Trail Ride'
        },
        {
          uuid: '0ada39e9-3db1-4f9e-b733-44e01238b4e1',
          alt: 'Nash Autism - Brinkleyville Trail Ride'
        }
      ]
    }
  }
}
</script>
