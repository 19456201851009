<template>
  <div>
    <v-row>
      <v-col>
        <p class="grey--text text--lighten-2 text-subtitle-1">
          Many times, throughout the year, local churches and volunteer fire departments will often cook meals such as Barbeque Chicken or Porkchop plates. As a show of support, our President of the company will purchase plates to feed all the office and shop staff. This happens many times throughout the year.

        </p>
        <ImageRow
          :images="images"
          title="Supporting Local VFD & Church Fundraisers"
          :lg="6"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageRow from '@/core/components/ImageRow'
export default {
  components: {
    ImageRow
  },
  data () {
    return {
      images: [
        {
          uuid: '6d5751d3-2de1-4ecd-9ccd-2941d7fc1eaa',
          alt: 'Coopers Fire and Rescue'
        },
        {
          uuid: '007c60f6-7378-4a43-8ec6-f93b69c5214f',
          alt: 'Coopers Fire and Rescue'
        }
      ]
    }
  }
}
</script>
