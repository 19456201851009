<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <v-row
      :data-blink-ops="dataBlinkOps"
      :data-blink-uuid="uuid"
      :style="bgImgStyles"
    >
      <v-col
        class="pa-0 cc-overlay"
      >
        <div class="pa-6 fill-height d-flex align-center">
          <div>
            <h1 class="text-h4 text-md-h1 white--text text-uppercase font-weight-medium">
              <span class="cc-border-bottom-primary">{{ title }}</span>
            </h1>
            <h2 :class="`text-subtitle-1 text-md-h4 white--text font-weight-bold`">{{ subtitle }}</h2>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '50vh'
    },
    uuid: {
      type: String,
      default: 'f3e06175-6eb8-4fd6-9fd1-3fcca731db01'
    },
    customImageOps: {
      type: Object,
      default: () => ({
        smAndDown: null,
        mdAndUp: null
      })
    }
  },
  computed: {
    bgImgStyles () {
      return `height: ${this.height}; background-color: #212121 `
    },
    dataBlinkOps () {
      const screen = this.$vuetify.breakpoint.smAndDown ? 'smAndDown' : 'mdAndUp'
      const propsOps = this.customImageOps && this.customImageOps.smAndDown ? 'CustomImageOps' : 'DefaultImageOps'
      const state = screen + propsOps
      const ops = {
        smAndDownDefaultImageOps: 'resize: 900; quality: smart; filter: sedis',
        mdAndUpDefaultImageOps: 'resize: 1920; quality: smart; filter: sedis',
        smAndDownCustomImageOps: this.customImageOps.smAndDown || null,
        mdAndUpCustomImageOps: this.customImageOps.mdAndUp || null
      }

      return ops[state]
    }
  }
}
</script>
