<template>
  <div>
    <v-row class="mt-4">
      <v-col>
        <v-card class="transparent" elevation="12">
          <div class="primary text-h6 text-md-h4 px-4 py-2 primary white--text text-uppercase">
            Local Education and Youth
          </div>
          <v-card-text class="grey--text text--lighten-2 text-subtitle-1">
            <p>
              C&C Industrial also recognizes its local educational facilities. The company has made monetary donations as well as material and labor donations to local high schools for agricultural facilities and to improve various sports necessities.
            </p>
            <p>
              The company also recognizes the youth community as well, by its recent contribution and donations of material and labor for a local park and sports complex known as Miracle Park located in Elm City, NC.
            </p>
            <ImageRow
              :images="images"
              title="Local Education - C&C Industrial"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageRow from '@/core/components/ImageRow'

export default {
  components: {
    ImageRow
  },
  data () {
    return {
      images: [
        {
          uuid: '54dd1553-d350-4517-9ccf-dbc1540e163e',
          alt: 'Southern Nash High School'
        },
        {
          uuid: '3d947562-df69-472d-b96d-aef8d83c74ef',
          alt: 'Miracle Park - Elm City, NC'
        },
        {
          uuid: '6befc71d-96d7-4a5e-8252-b967d720e1f9',
          alt: 'Miracle Park - Elm City, NC'
        }
      ]
    }
  }
}
</script>
