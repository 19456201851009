<template>
  <div>
    <v-row
      v-if="images && images.length > 0"
      justify="center"
    >
      <v-col
        v-for="(i, idx) in images"
        :key="i.uuid"
        class="cursor"
        cols="6"
        sm="4"
        :lg="lg"
        @click="$vuetify.breakpoint.mdAndUp ? openImageDialog(i, idx) : ''"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              flat
              class="pa-0 transparent"
            >
              <img
                data-blink-ops="scale-crop: 600x400; scale-crop-position: smart_faces_points;"
                :data-blink-uuid="i.uuid"
                class="mx-auto rounded cursor"
                :alt="i.alt"
                width="100%"
                height="auto"
              />
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="secondary"
                  class="mb-1"
                >
                  <div class="text-center pa-2 font-weight-bold">
                    {{ i.alt}}
                  </div>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <ImageRowDialog
      v-if="$vuetify.breakpoint.mdAndUp && activeImages.length > 0"
      :show="showImageDialog"
      :title="imageDialogTitle"
      :initialImage="initialImage"
      :images="activeImages"
      @close="showImageDialog = false"
    />
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array
    },
    title: {
      type: String
    },
    lg: {
      type: Number,
      default: 3
    }
  },
  components: {
    ImageRowDialog: () => import(/* webpackChunkName: "concrete-services-image-dialog" */ '../components/ImageRowDialog')
  },
  data () {
    return {
      showImageDialog: false,
      imageDialogTitle: null,
      initialImage: 0,
      activeImages: []
    }
  },
  methods: {
    openImageDialog (i, idx) {
      this.initialImage = idx
      this.activeImages = this.images.length > 0 ? this.images : i.images ? i.images : []
      this.imageDialogTitle = this.title ? this.title : i.title
      this.showImageDialog = true
    },
    closeImageDialog () {
      this.showImageDialog = false
      this.imageDialogTitle = null
      this.activeImages = []
    }
  },
  mounted () {
    if (this.title) this.imageDialogTitle = this.title
    if (this.images.length > 0) this.activeImages = this.images
  }
}
</script>
