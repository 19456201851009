<template>
  <div class="grey darken-4">
    <BannerHeader
      title="Community Outreach"
      :height="$vuetify.breakpoint.mdAndDown ? '25vh' : '50vh'"
      uuid="6d5751d3-2de1-4ecd-9ccd-2941d7fc1eaa"
    />
    <v-container>
      <CommunityIntro class="mb-8 py-4" />
    </v-container>
    <v-container class="pb-16">
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-card
            color="transparent"
            elevation="12"
            height="100%"
          >
            <v-card-text class="text-h6 text-md-h4 py-2 primary white--text text-uppercase">
              Local Fundraisers
            </v-card-text>
            <v-card-text>
              <CommunityLocalFoodFundraisers />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-card
            color="transparent"
            elevation="12"
            height="100%"
          >
            <v-card-text class="text-h6 text-md-h4 py-2 primary white--text text-uppercase">
              Meals on Wheels
            </v-card-text>
            <v-card-text>
              <CommunityMealsOnWheels />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <CommunityNashAutism />
      <CommunityLocalEducation />
    </v-container>
  </div>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
import CommunityIntro from '../components/CommunityIntro'
import CommunityMealsOnWheels from '../components/CommunityMealsOnWheels'
import CommunityLocalFoodFundraisers from '../components/CommunityLocalFoodFundraisers'
import CommunityNashAutism from '../components/CommunityNashAutism'
import CommunityLocalEducation from '../components/CommunityLocalEducation'

export default {
  metaInfo: {
    title: 'Community',
    description: 'AT C&C INDUSTRIAL, our company has always had deep roots tied to our community. We are a locally owned company that remembers where we came from to get to where we are. We strive to give back to our community by various ways.'
  },
  components: {
    CommunityIntro,
    CommunityLocalFoodFundraisers,
    CommunityMealsOnWheels,
    CommunityNashAutism,
    CommunityLocalEducation,
    BannerHeader
  }
}
</script>
